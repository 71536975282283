import React from 'react';

import Layout from 'components/Layout';
import Container from 'components/Container';
import cannabisImg from "../../images/cannabis/boomer.png";
import yellowDiamonds from "../../images/yellow-diamonds.svg";
import {Link} from "gatsby";
import Seo from "../../components/Seo";

import ambulance from "../../images/cannabis/ambulance.png";
import blockchain from "../../images/cannabis/blockchain.png";
import july2021 from "../../images/cannabis/July2021-CD.png";
import boomers from "../../images/cannabis/september2019-boomers.png";
import greatideas from "../../images/cannabis/greatideas-1.png";
import launchpad from "../../images/cannabis/launchpad-1.png";
import quicktips from "../../images/cannabis/quicktips-cd-2021.png";
import faststats from "../../images/cannabis/sept2019-cd-faststats.png";
import contents from "../../images/cannabis/july2021-contents-cd.png";

const CannabisPage = () => {
    return (
            <Layout pageName="cannabis">
                <Seo
                        title={'Cannabis Dispensary'}
                />
                <Container>
                    <div className="grid grid-cols-1 gap-10">
                        <div className="grid md:grid-cols-6 md:gap-10 content-center md:mb-16">
                            <img src={cannabisImg} alt="Cannabis Dispensary" className="md:col-span-3 w-full"/>
                            <div className="text-left md:col-span-3 lg:col-span-2 mt-4 md:mt-0">
                                <h2 className="text-7xl font-serif">Cannabis Dispensary</h2>
                                <h3 className="font-mono text-xl mt-4">November 2017 — Present</h3>
                                <img src={yellowDiamonds} alt="" className="mt-6"/>
                                <p className="mt-8">In 2017, Cannabis Dispensary was created as the first B2B publication in North America to focus solely on the dispensary market.</p>
                                <p className="mt-4">As art director, I concepted and built out the brand identity and worked with editors to map out the print component.</p>
                                <p className="mt-4">In the 4 years I spent with Cannabis Dispensary, it was recognized by Folio as the 2018 Best New Publication, as well as being awarded National Gold for Best Magazine Design by the American Society of Business Publication Editors.</p>
                            </div>
                        </div>
                        <img src={ambulance} alt="" className="max-w-full" />
                        <img src={blockchain} alt="" className="max-w-full" />
                        <img src={july2021} alt="" className="max-w-full" />
                        <div className="grid md:grid-cols-2 gap-10">
                            <img src={launchpad} alt="" className="max-w-full"/>
                            <img src={quicktips} alt="" className="max-w-full"/>
                        </div>
                        <img src={greatideas} alt="" className="max-w-full" />
                        <img src={boomers} alt="" className="max-w-full" />
                        <div className="grid md:grid-cols-2 gap-10">
                            <img src={faststats} alt="" className="max-w-full"/>
                            <img src={contents} alt="" className="max-w-full"/>
                        </div>
                        <div className="md:mt-16">
                            <Link to="/work/quality-assurance" className="text-4xl font-serif hover:text-pink transition-colors duration-200">Next Project: Quality Assurance</Link>
                        </div>
                    </div>
                </Container>
            </Layout>
    );
};

export default CannabisPage;
